<template>
<TopBar/>
<Header/>
<NavBar/>

  <section>
      <div class="container">
          <div class="breadcrumbs">
              <ul class="background">
                  <li> <router-link to = "/">Home</router-link> </li> <span class="arrow">></span>
                  <li>Staffs</li><span class="arrow">></span>
                  <li>{{title}}</li>
              </ul>
          </div>
          <div class="page">
              <div class="page-content">
                  <h1>{{title}}</h1>
                  <div class="team-wrapper">
                    
                    <div class="team">
                    <div class="team_member">
                        <div class="team_img">
                        <img src="img/Staffs/Principal.png" alt="Principal">
                        </div>
                        <h3>Yesh B Ghalley</h3>
                        <p class="role">Principal</p>
                        <p class="contact"><img src="@/assets/icon/phone-solid.svg" alt=""> 17618345/77141433</p>
                        <p class="mail"><img src="@/assets/icon/envelope-regular.svg" alt=""> yashbdr@education.gov.bt</p>
                    </div>
                    <div class="team_member">
                        <div class="team_img">
                        <img src="img/Staffs/Samdrup.png" alt="Vice Principal">
                        </div>
                        <h3>Samdrup Wangdi</h3>
                        <p class="role">V Principal</p>
                        <p class="contact"><img src="../../assets/icon/phone-solid.svg" alt=""> 17409592</p>
                        <p class="mail"><img src="../../assets/icon/envelope-regular.svg" alt=""> samdrup_wangdi@education.gov.bt</p>
                    </div>
                    <div class="team_member">
                        <div class="team_img">
                        <img src="img/Staffs/PemaW.png" alt="Vice Principal">
                        </div>
                        <h3>Pema Wangchuk</h3>
                        <p class="role">V Principal</p>
                        <p class="contact"><img src="../../assets/icon/phone-solid.svg" alt=""> 77859188</p>
                        <p class="mail"><img src="../../assets/icon/envelope-regular.svg" alt=""> pemawang_chuk@education.gov.bt</p>
                    </div>
                    
                   
                    </div>
      </div>
   
   
              </div>
              
          <div class="sidebar">
              <StaffsQuickLinks/>
              <RecentPosts/>
          </div>
          </div>
      </div>
  </section>
  <Footer/>
</template>

<script >
import TopBar from "../../components/page/TopBar.vue";
import Header from "../../components/page/Header.vue";
import NavBar from "../../components/page/NavBar.vue";
import RecentPosts from "../../components/page/RecentPosts.vue";
import { ref } from '@vue/reactivity';
import StaffsQuickLinks from "../../components/page/StaffsQuickLinks.vue";
import Footer from "../../components/page/Footer.vue";




export default {
    name: 'Background',    
    components: { TopBar, Header, NavBar, RecentPosts, StaffsQuickLinks, Footer },
    setup(){
        const title= ref('School Administration')
        return {title}
    }
    
  
}
</script>

<style scoped>

.team-wrapper{
  margin-top: 4rem;
  
}
.team{
  display: flex;
  justify-content: center;
  width: auto;
  text-align: center;
  flex-wrap: wrap;
}

.team .team_member{
  background: var(--secondary);
  margin: 2.5rem 0.5rem;  
  width: 100%;
  padding: 1rem;
  line-height: 1.2rem;
  color: #a7a2a2;  
  position: relative;
  box-sizing: border-box;
  
  
}

.team .team_member h3{
  color: var(--primary);
  font-size: 1.2rem;
  margin-top: 4.5rem
}
.team .team_member .contact, .mail{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.team .team_member .contact img{
    width: 1.7rem;
    padding-right: 0.5rem;
    filter: invert(1);
}
.team .team_member .mail img{
    width: 1.7rem;
    padding-right: 0.5rem;
    filter: invert(1);
}
.team .team_member .mail{
    font-size: 0.85rem;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-transition: 1.3s;
    -moz-transition: 1.3s;
    transition: 1.3s;     
  
    -webkit-transition-timing-function: linear;
    -moz-transition-timing-function: linear;
    transition-timing-function: linear;
}
.team .team_member p.role{
  color: #ccc;
  margin: 12px 0;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
}
.team .team_member p.mail{
    overflow:hidden;    
}
.team .team_member p.mail:hover{
    
    margin-left: -25px;
    z-index: 3;

}
.team .team_member .team_img{
  position: absolute;
  top: -75px;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: var(--primary);
}

.team .team_member .team_img img{
  width: 150px;
  height: 150px;
  padding: 5px;
}
@media (min-width:768px) {
    .team .team_member{
        width: 45%;
    }
}
@media (min-width:1100px) {
    .team .team_member{
        width: 30%;
    }
}
</style>